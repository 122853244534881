import React, { useEffect } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { muiTheme, theme } from 'constants/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import LogRocket from 'logrocket';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useRef } from 'react';
import Head from 'next/head';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import GlobalStyles from 'components/GlobalStyles/GlobalStyles';
import { setConfig } from 'cloudinary-build-url';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, CssFontSource } from '@stripe/stripe-js';
import * as Fathom from 'fathom-client';
import Script from 'next/script';
import cookie from 'cookie';

setConfig({
  cloudName: 'solin-fitness',
});

import 'components/GlobalStyles/global.css';
import 'components/GlobalStyles/fonts.css';

import 'react-multi-carousel/lib/styles.css';
import 'nprogress/nprogress.css';
import { LOGIN } from 'constants/routes';
import useAuthStore from 'hooks/useAuthStore';

const IS_DEV = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
const HEAP_ID = process.env.NEXT_PUBLIC_HEAP_ID;

const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PK;
const stripePromise = loadStripe(stripeKey as string);
const fontStyle: CssFontSource = {
  cssSrc:
    'https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500&display=swap',
};

// TODO once we have domain change this
if (!IS_DEV) {
  ReactGA.initialize('G-9H69RM2RTN');
  LogRocket.init('solinfitness/solintv-consumer');
}

interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}
declare global {
  interface Window {
    heap: Heap;
  }
}

const themeConst = createMuiTheme(muiTheme);

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

type Props = AppProps & {
  accessToken?: string;
};

const SolinApp = ({ Component, pageProps, accessToken }: Props) => {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          // ✅ globally set retries to 2 attemps
          retry: 2,
          // ✅ globally default to 20 seconds
          staleTime: 1000 * 20,
        },
      },
    });
  }
  const router = useRouter();

  // const { isSignedIn, setHasSessionCookies } = useAuthStore();

  /**
   * This will check if the user is not signed in but a session cookie exists
   * Therefore, means they logged in on dashboard and are coming over to the site so we should
   * mark them as signed in
   *
   * This will then enbale the profile and metadata useQueries in the header and update the auth state
   */
  // useEffect(() => {
  //   console.log({ accessToken, isSignedIn });
  //   if (accessToken && !isSignedIn) {
  //     setHasSessionCookies();
  //   }
  // }, [accessToken, isSignedIn]);

  useEffect(() => {
    const userSession = localStorage.getItem('authtoken');
    if (userSession) {
      localStorage.clear();
      queryClientRef.current?.removeQueries(['profile', 'metadata']);
      router.push(LOGIN);
    }
  }, []);

  useEffect(() => {
    Fathom.load('KNOYTOLG', {
      includedDomains: ['solin.stream', 'www.solin.stream'],
    });

    const onRouteChangeComplete = () => Fathom.trackPageview();

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () =>
      router.events.off('routeChangeComplete', onRouteChangeComplete);
  }, []);

  const FacebookPixel = () => {
    useEffect(() => {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init('330793878378588');
          ReactPixel.pageView();
        });
    });
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={themeConst}>
        <QueryClientProvider client={queryClientRef.current}>
          <Elements stripe={stripePromise} options={{ fonts: [fontStyle] }}>
            <Head>
              <title>Solin - The Home of Social Fitness</title>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <meta
                property="og:title"
                content="Solin - The Home of Social Fitness"
                key="title"
              />
              <meta
                property="og:description"
                content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
              />
              <meta
                property="og:image"
                content="https://www.solin.stream/images/ogImage.png"
              />
              <meta
                name="description"
                key="description"
                content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
              />
              <link rel="icon" href="/images/favicon.ico" />

              <script
                src="https://apis.google.com/js/api.js"
                type="text/javascript"
              />
              <Script
                strategy="beforeInteractive"
                dangerouslySetInnerHTML={{
                  __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                 heap.load(${HEAP_ID});`,
                }}
              />
            </Head>
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
            </Hydrate>
            <FacebookPixel />
            <GlobalStyles />
            <ReactQueryDevtools initialIsOpen={false} />
          </Elements>
        </QueryClientProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

// SolinApp.getInitialProps = async (appContext: AppContext) => {
//   const appProps = await App.getInitialProps(appContext);

//   const cookies = cookie.parse(appContext.ctx?.req?.headers.cookie || '');
//   const accessToken = cookies?.accessToken;

//   return { ...appProps, accessToken };
// };

export default Sentry.withProfiler(SolinApp);
