import { COLORS, PRIMARY_HUE, SECONDARY_HUE } from './../../constants/theme';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

html {
  /*
    Silence the warning about missing Reach Dialog styles
  */
  --reach-dialog: 1;
  --reach-menu-button: 1;

  /* COLORS */
  /* Gray Colors */
  --color-gray-100: ${COLORS.grays[100]};
  --color-gray-300: ${COLORS.grays[300]};
  --color-gray-500: ${COLORS.grays[500]};
  --color-gray-700: ${COLORS.grays[700]};
  --color-gray-900: ${COLORS.grays[900]};

  /* Primary Colors */
  --color-primary-hue: ${PRIMARY_HUE};
  --color-primary-100: ${COLORS.primary[100]};
  --color-primary-300: ${COLORS.primary[300]};
  --color-primary-500: ${COLORS.primary[500]};
  --color-primary-700: ${COLORS.primary[700]};
  --color-primary-900: ${COLORS.primary[900]};


  /* Secondary Colors */
  --color-secondary-hue: ${SECONDARY_HUE};
  --color-secondary-100: ${COLORS.secondary[100]};
  --color-secondary-300: ${COLORS.secondary[300]};
  --color-secondary-500: ${COLORS.secondary[500]};
  --color-secondary-700: ${COLORS.secondary[700]};
  --color-secondary-900: ${COLORS.secondary[900]};

  /* Base Colors */
  --color-black-500: ${COLORS.base.black[500]};
  --color-black-700: ${COLORS.base.black[700]};
  --color-black-700-60: ${COLORS.base.black['700/60']};
  --color-black-700-80: ${COLORS.base.black['700/80']};
  --color-black-transparent: ${COLORS.base.transparentBlack};
  --color-white: ${COLORS.base.white};
  --color-white-transparent: ${COLORS.base.transparentWhite};

  /* Off Whites */
  --color-off-white-100: ${COLORS.offWhite[100]};
  --color-off-white-300: ${COLORS.offWhite[300]};
  --color-off-white-500: ${COLORS.offWhite[500]};
  --color-off-white-700: ${COLORS.offWhite[700]};
  --color-off-white-900: ${COLORS.offWhite[900]};

  /* Stop lights */
  --color-stop-light-green-500: ${COLORS.stopLightGreen[500]};
  --color-stop-light-green-700: ${COLORS.stopLightGreen[700]};
  --color-stop-light-yellow-500: ${COLORS.stopLightYellow[500]};
  --color-stop-light-yellow-700: ${COLORS.stopLightYellow[700]};

  /* TYPOGRAPHY */

  /* Font Sizes */
  --font-size-display-01: calc(64 / 16 * 1rem);
  --font-size-header-01: calc(58 / 16 * 1rem);
  --font-size-header-02: calc(42 / 16 * 1rem);
  --font-size-header-03: calc(36 / 16 * 1rem);
  --font-size-subtitle-01: calc(28 / 16 * 1rem);
  --font-size-subtitle-02: calc(24 / 16 * 1rem);
  --font-size-body-large-01: calc(20 / 16 * 1rem);
  --font-size-body-01: calc(16 / 16 * 1rem);
  --font-size-body-02: calc(14 / 16 * 1rem);
  --font-size-body-01-bold: calc(16 / 16 * 1rem);
  --font-size-body-02-bold: calc(14 / 16 * 1rem);
  --font-size-button-01: calc(24 / 16 * 1rem);
  --font-size-button-02: calc(20 / 16 * 1rem);
  --font-size-button-03: calc(16 / 16 * 1rem);

  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  /* Letter Spacing */
  --letter-spacing-xl: -0.03em;
  --letter-spacing-lg: -0.02em;
  --letter-spacing-md: -0.01em;
  --letter-spacing-sm: 0.02em;
  --letter-spacing-button: 0.01em;

  /* Line Height */
  --line-height-sm: 1.25em;
  --line-height-md: 1.30em;


  /* SHADOWS */

  /* Thumbnail Shadow */
  --shadow-thumbnail-default-old:
    0px 3px 6px rgba(0, 0, 0, 0.06),
    0px 3px 9px rgba(0, 0, 0, 0.1);

  --shadow-thumbnail-default:
    0 1px 1px rgba(0,0,0,0.25),
    0 2px 2px rgba(0,0,0,0.20),
    0 4px 4px rgba(0,0,0,0.15),
    0 8px 8px rgba(0,0,0,0.10),
    0 16px 16px rgba(0,0,0,0.05);

  --shadow-thumbnail-hover:
    0px 10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1);

  /* Button Shadow */
  --shadow-button-small:
    0px 1px 1px rgba(0, 0, 0, 0.075),
    1px 2px 2px rgba(0, 0, 0, 0.075),
    2px 4px 4px rgba(0, 0, 0, 0.075),
    3px 6px 8px rgba(0, 0, 0, 0.075);

  --shadow-button-medium:
    0px 1px 1px rgba(0, 0, 0, 0.075),
    1px 2px 2px rgba(0, 0, 0, 0.075),
    2px 4px 4px rgba(0, 0, 0, 0.075),
    4px 8px 8px rgba(0, 0, 0, 0.075);

  --shadow-button-large:
    0px 1px 1px rgba(0, 0, 0, 0.075),
    1px 2px 2px rgba(0, 0, 0, 0.075),
    2px 4px 4px rgba(0, 0, 0, 0.075),
    4px 8px 8px rgba(0, 0, 0, 0.075),
    8px 16px 16px rgba(0, 0, 0, 0.075);

  --shadow-post:
    2px 4px 8px rgba(0, 0, 0, 0.1),
    3px 6px 12px rgba(0, 0, 0, 0.1);

  --shadow-layered-medium:
    0px 1px 1px rgba(0, 0, 0, 0.075),
    0px 2px 2px rgba(0, 0, 0, 0.075),
    0px 4px 4px rgba(0, 0, 0, 0.075),
    0px 8px 8px rgba(0, 0, 0, 0.075);

  /* SPACING */
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-80: 80px;
  --spacing-96: 96px;
  --spacing-160: 160px;

  /*
    Create a stacking context, without a z-index.
    This ensures that all portal content (modals and tooltips) will
    float above the app.
  */
  isolation: isolate;
}

#__next {
  min-height: 100%;
  height: 100%;
}
`;

export default GlobalStyles;
