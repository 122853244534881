export const SIGN_UP = '/sign-up';
export const VOD_VIDEO = '/workout/:id';
export const LIVE = '/live/:id';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const SETTINGS = '/settings';
export const HOME = '/';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const DISCOVERY = '/discovery';
export const DANCE_STUDIO = '/dance-studio';
export const BECOME_CREATOR = '/become-a-creator';
export const LEARN_MORE = '/learn-more';
export const CREATE_ACCOUNT = '/create-account';
export const ABOUT_US = '/about-us';
export const FOR_CREATORS = '/for-creators';

export const LIVE_FUNC = (id: number) => `/live/${id}`;
export const CREATOR_LANDING_FUNC = (id: string) => `/${id}`;

export const SIGN_UP_LIVE_WORKOUT = (creatorPageUrl: string, liveId: number) =>
  `${creatorPageUrl}/live/${liveId}/purchase`;

export const SIGN_UP_PROGRAM = (creatorPageUrl: string, programId: number) =>
  `/${creatorPageUrl}/program/${programId}/purchase`;

export const SIGN_UP_EVENT = (creatorPageUrl: string, eventId: number) =>
  `/${creatorPageUrl}/event/${eventId}/purchase`;

export const SIGN_UP_SUBSCRIPTION = (creatorPageUrl: string) =>
  `/${creatorPageUrl}/subscription/purchase`;

// Account management

export const FORGOT_PASSWORD = '/password/forgot';
export const RESET_PASSWORD_ROUTE = '/password/reset';
